import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch } from 'vue';
import { QuestionCircleOutlined, DownOutlined } from '@ant-design/icons-vue';
import { queryAll, addMenuData, setMenu, deleteMenu } from '@/api/role';
import { getMenuTree } from '@/utils/setMenuTree';
const columns = [{
  title: '菜单标题',
  dataIndex: 'menuTitle',
  key: 'menuTitle'
}, {
  title: 'URL地址',
  dataIndex: 'menuUrl',
  key: 'menuUrl'
}, {
  title: 'name',
  dataIndex: 'menuName',
  key: 'menuName'
}, {
  title: '图标',
  dataIndex: 'menuIcon',
  key: 'menuIcon',
  slots: {
    customRender: 'menuIcon'
  }
}, {
  title: '类型',
  dataIndex: 'menuType',
  key: 'menuType',
  slots: {
    customRender: 'menuType'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];

const req = require.context('@/assets/icons', false, /\.svg$/);

const requireAll = requireContext => requireContext.keys();

const re = /\.\/(.*)\.svg/;
const svgIcons = requireAll(req).map(i => {
  return i.match(re)[1];
});
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    DownOutlined
  },

  setup() {
    const replaceFields = {
      key: 'key',
      title: 'menuTitle',
      value: "menuNo",
      children: 'childMenuList'
    };
    const tableData = ref([// {
      //   key: '1',
      //   menuName: '人员管理',
      //   url:'/personnel-management',
      //   type: 'directory',
      //   childMenuList: [
      //     {
      //       key: '1-1',
      //       menuName: '员工列表',
      //       url:'/personnel-management',
      //       type: 'menu',
      //       childMenuList: [
      //         {
      //           key: '1-1-1',
      //           menuName: '新增人员',
      //           url:'',
      //           type: 'button',
      //         }
      //       ]
      //     },
      //     {
      //       key: '1-2',
      //       menuName: '黑名单管理',
      //       url:'/personnel-management',
      //       type: 'menu',
      //     },
      //   ]
      // }
    ]);
    const menuVisible = ref(false);
    const switchForModal = ref('新增');

    const addMenu = () => {
      menuVisible.value = true;
      switchForModal.value = '新增';
    };

    const formRef = ref();
    const formState = reactive({
      key: null,
      menuType: 1,
      menuTitle: undefined,
      menuName: undefined,
      menuUrl: undefined,
      parentMenuNo: null,
      menuIcon: '',
      floorInSort: ''
    });
    const treeData = ref([// {
      //   key:0,
      //   menuTitle: '顶级菜单',
      //   menuNo:0,
      //   childMenuList: []
      // }
    ]);
    const rules = [];

    const toModification = val => {
      console.log('toModification', val);
      formState.menuType = val.menuType;
      formState.menuTitle = val.menuTitle;
      formState.menuName = val.menuName;
      formState.menuUrl = val.menuUrl;
      formState.parentMenuNo = val.parentMenuNo == 0 ? null : val.parentMenuNo;
      formState.menuIcon = val.menuIcon;
      formState.floorInSort = val.floorInSort;
      formState.key = val.menuNo;
      menuVisible.value = true;
      switchForModal.value = '修改';
    };

    const onDelete = async val => {
      console.log(val);
      const param = {};

      if (val.menuType == 3) {
        // param.buttonNo = (val.key.split('-'))[0]
        param.buttonNo = val.key;
        param.menuType = val.menuType;
      } else {
        param.menuNo = val.key;
        param.menuType = val.menuType;
      }

      console.log(param);
      let res = await deleteMenu(param);

      if (res.code == 200) {
        _message.success('删除成功');

        getTableData();
      }
    };

    const iconVisible = ref(false);

    const handleChange = value => {
      formState.menuIcon = value;
      iconVisible.value = false;
    };

    const getTableData = async () => {
      let res = await queryAll();

      try {
        treeData.value = JSON.parse(JSON.stringify(res.data.menuList));
        tableData.value = getMenuTree(res.data.menuList, 1); // console.log(res.data.menuList)
      } catch (error) {
        console.log(error);
      }
    };

    const handleOk = async () => {
      let res = {};

      if (switchForModal.value == '新增') {
        res = await addMenuData(formState);
      } else {
        const param = {
          menuType: formState.menuType,
          menuTitle: formState.menuTitle,
          menuName: formState.menuName,
          menuUrl: formState.menuUrl,
          parentMenuNo: formState.parentMenuNo,
          menuIcon: formState.menuIcon,
          floorInSort: formState.floorInSort
        };

        if (formState.menuType == 3) {
          param.buttonNo = formState.key;
        } else {
          param.menuNo = formState.key;
        }

        res = await setMenu(param);
      }

      if (res.code === 200) {
        menuVisible.value = false;
        getTableData(); // formRef.value.resetFields();

        formState.key = null;
        formState.menuType = 1;
        formState.menuTitle = undefined;
        formState.menuName = undefined;
        formState.menuUrl = undefined;
        formState.parentMenuNo = null;
        formState.menuIcon = '';
        formState.floorInSort = '';

        _message.success('添加成功');
      }

      console.log(res);
    };

    onMounted(() => {
      getTableData();
    });
    return {
      columns,
      tableData,
      toModification,
      onDelete,
      addMenu,
      menuVisible,
      formRef,
      formState,
      rules,
      replaceFields,
      treeData,
      svgIcons,
      iconVisible,
      handleChange,
      handleOk,
      switchForModal
    };
  }

});